<template>
    <ion-header>
        <ion-toolbar>
            <ion-row style="justify-content: space-between; align-items: center; flex-wrap: nowrap">
                <ion-col>
                    <h4>Добавить достопримечательность</h4>
                </ion-col>
                
                <ion-col size="auto">
                    <ion-button
                        fill="clear"
                        @click="closeModal"
                    >
                        <ion-icon
                            slot="icon-only"
                            color="dark"
                            name="close"
                        ></ion-icon>
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-toolbar>
    </ion-header>
    <TransitionBlock>
        <ion-content v-if="isMarkAdded">
            <ion-row style="width: 100%; height: 100%; justify-content: center; align-items: center;">
                Ваши данные успешно отправлены на рассмотрение ✔
            </ion-row>
        </ion-content>
        
        <ion-content
            v-else
            ref="addPointForm"
            class="ion-padding"
        >
            <InputDefault
                ref="nameInput"
                :auto-grow="$isMobile"
                :model-value="name"
                :rules="[ v => !!v || 'Укажите название' ]"
                hide-length
                label="Название"
                placeholder="Например: Новая ферма рыб"
                required
                @update:modelValue="val => updateFormInputs(val, 'name')"
            />
            
            <InputDefault
                ref="latitudeInput"
                :model-value="latitude"
                :rules="[ v => !!v || 'Укажите широту' ]"
                input-numbers
                label="Широта"
                pattern="[0-9]"
                placeholder="Например: 57.9315"
                required
                type="tel"
                @update:modelValue="val => updateFormInputs(val, 'latitude')"
            />
            
            <InputDefault
                ref="longitudeInput"
                v-model="longitude"
                :rules="[ v => !!v || 'Укажите долготу' ]"
                input-numbers
                label="Долгота"
                pattern="[0-9]"
                placeholder="Например: 59.9832"
                required
                type="tel"
                @update:modelValue="val => updateFormInputs(val, 'longitude')"
            />
            
            <InputDefault
                ref="placeInput"
                :auto-grow="$isMobile"
                :model-value="place"
                :rules="[ v => !!v || 'Укажите населенный пункт' ]"
                hide-length
                label="Населенный пункт"
                placeholder="Например: п. Уралец"
                required
                @update:modelValue="val => updateFormInputs(val, 'place')"
            />
            
            <InputDefault
                ref="descInput"
                :model-value="desc"
                :rules="[ v => !!v || 'Укажите описание' ]"
                auto-grow
                label="Описание"
                placeholder="Укажите описание"
                required
                @update:modelValue="val => updateFormInputs(val, 'desc')"
            />
            
            <ion-row style="justify-content: space-between; align-items: center; flex-wrap: nowrap">
                <ion-col size="auto">
                    <h4>Субъект МСП</h4>
                </ion-col>
                <ion-col>
                    <div class="tooltip">
                        <div
                            style="width: 20px; height: 20px; display:flex; justify-content: center; align-items: center; border-radius: 50%; border: 1px dotted rgba(0, 0, 0, 0.25); margin-top: 5px;"
                        >
                            <ion-icon
                                slot="icon-only"
                                name="help"
                            ></ion-icon>
                        </div>
                        
                        <span class="tooltiptext">Данные организации или ИП</span>
                    </div>
                </ion-col>
            </ion-row>
            
            <InputDefault
                ref="companyNameInput"
                :auto-grow="$isMobile"
                :model-value="companyName"
                :rules="[ v =>  (!isValuesOfCompanyNotEmpty || isValuesOfCompanyNotEmpty && !!v) || 'Укажите название субъекта МСП' ]"
                hide-length
                label="Название"
                placeholder="Например: ИП Иванов Иван Иванович"
                @update:modelValue="val => updateFormInputs(val, 'companyName')"
            />
            
            <InputDefault
                ref="companyINNInput"
                :max-length="12"
                :model-value="companyINN"
                :rules="[
                    v =>  (!isValuesOfCompanyNotEmpty || isValuesOfCompanyNotEmpty && !!v) || 'Укажите ИНН субъекта МСП',
                    v => (v.length === 0 || v.length > 0 && (v.length === 10 || v.length === 12)) || 'Неверный формат ИНН'
                ]"
                auto-grow
                hide-length
                input-numbers
                label="ИНН"
                pattern="[0-9]"
                placeholder="Например: 1111111111"
                type="tel"
                @update:modelValue="val => updateFormInputs(val, 'companyINN')"
            />
            
            <InputDefault
                ref="companyDescInput"
                :model-value="companyDesc"
                :rules="[ v =>  (!isValuesOfCompanyNotEmpty || isValuesOfCompanyNotEmpty && !!v) || 'Укажите контактную информацию субъекта МСП' ]"
                auto-grow
                label="Контактная информация"
                placeholder="Например: Email: test@ya.ru, Директор: Иванов Иван Иванович"
                @update:modelValue="val => updateFormInputs(val, 'companyDesc')"
            />
        </ion-content>
    </TransitionBlock>
    <ion-footer v-if="!isMarkAdded">
        <ion-row style="padding: 0.3rem 0.5rem; justify-content: flex-end">
            <ion-button
                :style="{ 'width': $isMobile ? '100%' : 'auto' }"
                color="primary"
                @click="addNewPoint"
            >Добавить
            </ion-button>
        </ion-row>
    </ion-footer>
</template>

<script>
import { alertController } from '@ionic/vue';
import {
    computed,
    defineComponent,
    ref,
} from 'vue';
import InputDefault from '@/components/InputDefault';
import axios from 'axios';
import TransitionBlock from '@/components/TransitionBlock';

export default defineComponent({
    name:       'AddPoint',
    components: {
        TransitionBlock,
        InputDefault,
    },
    
    setup() {
        const isMarkAdded = ref(false); // широта
        const latitude = ref(''); // широта
        const longitude = ref(''); // долгота
        const place = ref(''); // населенный пункт
        const name = ref(''); // название достопримечательности
        const desc = ref(''); // описание
        const companyName = ref(''); // название огранизации
        const companyINN = ref(''); // инн огранизации
        const companyDesc = ref(''); // описание огранизации
        
        const isValuesNotEmpty = computed(() => latitude.value || longitude.value || place.value || name.value || desc.value);
        const isValuesOfCompanyNotEmpty = computed(() => !!companyName.value || !!companyINN.value || !!companyDesc.value);
        
        return {
            latitude:    latitude,
            longitude:   longitude,
            place:       place,
            name:        name,
            desc:        desc,
            companyName: companyName,
            companyINN:  companyINN,
            companyDesc: companyDesc,
            
            isMarkAdded:               isMarkAdded,
            isValuesNotEmpty:          isValuesNotEmpty,
            isValuesOfCompanyNotEmpty: isValuesOfCompanyNotEmpty,
        };
    },
    
    methods: {
        async addNewPoint() {
            try {
                const latitudeValidate = this.$refs.latitudeInput.validate();
                const longitudeValidate = this.$refs.longitudeInput.validate();
                const descValidate = this.$refs.descInput.validate();
                const nameValidate = this.$refs.nameInput.validate();
                const placeValidate = this.$refs.placeInput.validate();
                const companyNameValidate = this.$refs.companyNameInput.validate();
                const companyINNValidate = this.$refs.companyINNInput.validate();
                const companyDescValidate = this.$refs.companyDescInput.validate();
                
                if (latitudeValidate && longitudeValidate && descValidate && nameValidate && placeValidate && companyNameValidate && companyINNValidate && companyDescValidate) {
                    const request = {
                        lat:     this.latitude,
                        lng:     this.longitude,
                        name:    this.name,
                        place:   this.place,
                        desc:    this.desc,
                        company: null,
                    };
                    
                    if (this.isValuesOfCompanyNotEmpty) {
                        request.company = {
                            name: this.companyName,
                            inn:  this.companyINN,
                            desc: this.companyDesc,
                        };
                    }
                    
                    const response = await axios.post('/api/geoobject/add', request);
                    
                    if (response.data.data.success) {
                        this.isMarkAdded = true;
                        
                        this.latitude = '';
                        this.longitude = '';
                        this.name = '';
                        this.place = '';
                        this.desc = '';
                    }
                    else {
                        this.$error('Не удалось добавить новую достопримечательность. Попробуйте позже или обратитесь в техподдержку.');
                    }
                }
                else {
                    this.$error('Есть незаполненные поля');
                }
            }
            catch (err) {
                console.error('ERROR addNewPoint');
                console.error(err);
                this.$error('Произошла ошибка при добавлении новой достопримечательности. Попробуйте позже или обратитесь в техподдержку.');
            }
        },
        
        updateFormInputs(val, formKeyName) {
            this[formKeyName] = val;
        },
        
        async closeModal() {
            if (this.isValuesNotEmpty) {
                await this.presentAlertConfirm('Вы уверены, что хотите закрыть окно добавления достопримечательности? Все несохраненные данные будут утеряны!');
            }
            else {
                this.$emit('close');
            }
        },
        
        async presentAlertConfirm(message) {
            const alert = await alertController
                .create({
                    cssClass: 'add-point-confirm-close',
                    header:   'Подтверждение действия',
                    message:  message,
                    buttons:  [
                        {
                            text:     'Отмена',
                            role:     'cancel',
                            cssClass: 'secondary',
                        },
                        {
                            text:    'Подтвердить',
                            handler: () => {
                                this.$emit('close');
                            },
                        },
                    ],
                });
            return alert.present();
        },
        
    },
});
</script>

<style>
</style>
