<template>
    <ion-header>
        <ion-toolbar>
            <ion-row style="justify-content: space-between; align-items: center; flex-wrap: nowrap">
                <ion-col>
                    <h4>Добавить событие</h4>
                </ion-col>
                
                <ion-col size="auto">
                    <ion-button
                        fill="clear"
                        @click="closeModal"
                    >
                        <ion-icon
                            slot="icon-only"
                            color="dark"
                            name="close"
                        ></ion-icon>
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-toolbar>
    </ion-header>
    <TransitionBlock>
        <ion-content v-if="isMarkAdded">
            <ion-row style="width: 100%; height: 100%; justify-content: center; align-items: center;">
                Ваши данные успешно отправлены на рассмотрение ✔
            </ion-row>
        </ion-content>
        
        <ion-content
            v-else
            ref="addPointForm"
            class="ion-padding"
        >
            <InputDefault
                ref="nameInput"
                :auto-grow="$isMobile"
                :model-value="name"
                :rules="[ v => !!v || 'Укажите название' ]"
                hide-length
                label="Название события"
                placeholder="Например: Открытый фестиваль"
                required
                @update:modelValue="val => updateFormInputs(val, 'name')"
            />
            
            <InputDefault
                ref="latitudeInput"
                :model-value="latitude"
                :rules="[ v => !!v || 'Укажите широту' ]"
                input-numbers
                label="Широта"
                pattern="[0-9]"
                placeholder="Например: 57.9315"
                required
                type="tel"
                @update:modelValue="val => updateFormInputs(val, 'latitude')"
            />
            
            <InputDefault
                ref="longitudeInput"
                v-model="longitude"
                :rules="[ v => !!v || 'Укажите долготу' ]"
                input-numbers
                label="Долгота"
                pattern="[0-9]"
                placeholder="Например: 59.9832"
                required
                type="tel"
                @update:modelValue="val => updateFormInputs(val, 'longitude')"
            />
            
            <InputDefault
                ref="placeInput"
                :auto-grow="$isMobile"
                :model-value="place"
                :rules="[ v => !!v || 'Укажите населенный пункт' ]"
                hide-length
                label="Населенный пункт"
                placeholder="Например: п. Уралец"
                required
                @update:modelValue="val => updateFormInputs(val, 'place')"
            />
            
            <InputDefault
                ref="descInput"
                :model-value="desc"
                :rules="[ v => !!v || 'Укажите описание' ]"
                auto-grow
                label="Описание"
                placeholder="Укажите описание"
                required
                @update:modelValue="val => updateFormInputs(val, 'desc')"
            />
            
            <InputDefault
                ref="organizatorInput"
                :model-value="organizator"
                label="Организатор события"
                placeholder="Укажите организатора"
                @update:modelValue="val => updateFormInputs(val, 'organizator')"
            />
            
            <InputDefault
                ref="websiteInput"
                :model-value="website"
                :rules="[ v => !!v || 'Укажите сайт события' ]"
                label="Сайт события"
                placeholder="Укажите сайт события"
                @update:modelValue="val => updateFormInputs(val, 'website')"
            />
        </ion-content>
    </TransitionBlock>
    <ion-footer v-if="!isMarkAdded">
        <ion-row style="padding: 0.3rem 0.5rem; justify-content: flex-end">
            <ion-button
                :style="{ 'width': $isMobile ? '100%' : 'auto' }"
                color="primary"
                @click="addNewEvent"
            >Добавить
            </ion-button>
        </ion-row>
    </ion-footer>
</template>

<script>
import { alertController } from '@ionic/vue';
import {
    computed,
    defineComponent,
    ref,
} from 'vue';
import InputDefault from '@/components/InputDefault';
import axios from 'axios';
import TransitionBlock from '@/components/TransitionBlock';

export default defineComponent({
    name:       'AddEvent',
    components: {
        TransitionBlock,
        InputDefault,
    },
    
    setup() {
        const isMarkAdded = ref(false); // широта
        const latitude = ref(''); // широта
        const longitude = ref(''); // долгота
        const place = ref(''); // населенный пункт
        const name = ref(''); // название события
        const desc = ref(''); // описание
        const website = ref(''); // описание
        const organizator = ref(''); // организатор
        
        const isValuesNotEmpty = computed(() => latitude.value || longitude.value || place.value || name.value || desc.value);
        
        return {
            latitude:         latitude,
            longitude:        longitude,
            place:            place,
            name:             name,
            desc:             desc,
            website:          website,
            organizator:      organizator,
            isMarkAdded:      isMarkAdded,
            isValuesNotEmpty: isValuesNotEmpty,
        };
    },
    
    methods: {
        async addNewEvent() {
            try {
                const latitudeValidate = this.$refs.latitudeInput.validate();
                const longitudeValidate = this.$refs.longitudeInput.validate();
                const descValidate = this.$refs.descInput.validate();
                const nameValidate = this.$refs.nameInput.validate();
                const placeValidate = this.$refs.placeInput.validate();
                
                if (latitudeValidate && longitudeValidate && descValidate && nameValidate && placeValidate) {
                    const request = {
                        lat:         this.latitude,
                        lng:         this.longitude,
                        name:        this.name,
                        place:       this.place,
                        desc:        this.desc,
                        organizator: this.organizator,
                        website:     this.website,
                        isEvent:     true,
                    };
                    const response = await axios.post('/api/geoobject/add', request);
                    
                    if (response.data.data.success) {
                        this.isMarkAdded = true;
                        
                        this.latitude = '';
                        this.longitude = '';
                        this.name = '';
                        this.place = '';
                        this.desc = '';
                        this.organizator = '';
                        this.website = '';
                    }
                    else {
                        this.$error('Не удалось добавить новое событие. Попробуйте позже или обратитесь в техподдержку.');
                    }
                }
            }
            catch (err) {
                console.error('ERROR addNewEvent');
                console.error(err);
                this.$error('Произошла ошибка при добавлении нового события. Попробуйте позже или обратитесь в техподдержку.');
            }
        },
        
        updateFormInputs(val, formKeyName) {
            this[formKeyName] = val;
        },
        
        async closeModal() {
            if (this.isValuesNotEmpty) {
                await this.presentAlertConfirm('Вы уверены, что хотите закрыть окно добавления события? Все несохраненные данные будут утеряны!');
            }
            else {
                this.$emit('close');
            }
        },
        
        async presentAlertConfirm(message) {
            const alert = await alertController
                .create({
                    cssClass: 'add-point-confirm-close',
                    header:   'Подтверждение действия',
                    message:  message,
                    buttons:  [
                        {
                            text:     'Отмена',
                            role:     'cancel',
                            cssClass: 'secondary',
                        },
                        {
                            text:    'Подтвердить',
                            handler: () => {
                                this.$emit('close');
                            },
                        },
                    ],
                });
            return alert.present();
        },
        
    },
});
</script>

<style>
</style>
