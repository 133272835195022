<template>
    <ion-spinner
        v-if="!loadMap"
        class="loader"
    />
    
    <div
        v-else
        class="map-container"
    >
        <div class="map-list">
            <div class="items">
                <MapItemsList
                    :placemarks="placemarks"
                    @getPlacemark="getPlacemark"
                    @openModalEventInfo="openModalEventInfo"
                    @openModalInfo="openModalPointInfo"
                >
                    <template v-slot:empty-list-message>
                        Достопримечательности никто не добавлял или еще не прошли модерацию. Попробуйте получить их позже.
                    </template>
                </MapItemsList>
            </div>
            
            <div
                class="buttonAddWrapper"
                style="display: flex; flex-direction: row; justify-content: space-between;"
            >
                <ion-text
                    class="buttonAdd"
                    color="primary"
                    style="width: 50%; display:flex; align-items: center; justify-content: center"
                    @click="openModalAddPoint"
                >
                    + Добавить достопримечательность
                </ion-text>
                
                <ion-text
                    class="buttonAdd"
                    color="primary"
                    style="width: 50%; display:flex; align-items: center; justify-content: center"
                    @click="openModalEventPoint"
                >
                    + Добавить событие
                </ion-text>
            </div>
        </div>
        
        <div class="map">
            <div
                id="map"
                style="width: 100%; height: 100%;"
            ></div>
        </div>
        
        <ion-modal
            :is-open="isOpenModalAddPoint"
            css-class="my-custom-class"
            @didDismiss="isOpenModalAddPoint = false"
        >
            <AddPoint
                :data="data"
                @close="isOpenModalAddPoint = false"
            ></AddPoint>
        </ion-modal>
        
        <ion-modal
            :is-open="isOpenModalEventPoint"
            css-class="my-custom-class"
            @didDismiss="isOpenModalEventPoint = false"
        >
            <AddEvent
                :data="data"
                @close="isOpenModalEventPoint = false"
            ></AddEvent>
        </ion-modal>
        
        <ion-modal
            :is-open="isOpenModalPointsList"
            css-class="my-custom-class"
            @didDismiss="isOpenModalPointsList = false"
        >
            <MapItemsListDialog
                :placemarks="placemarks"
                active-second-button
                @close="isOpenModalPointsList = false"
                @getPlacemark="getPlacemark"
                @openAddEvent="openModalEventPoint"
                @openAddPoint="openModalAddPoint"
                @openModalEventInfo="openModalEventInfo"
                @openModalInfo="openModalPointInfo"
            >
                <template v-slot:title>
                    Список достопримечательностей
                </template>
                
                <template v-slot:add-title>
                    + Добавить достопримечательность
                </template>
                
                <template v-slot:add-event-title>
                    + Добавить событие
                </template>
                
                <template v-slot:empty-list-message>
                    Достопримечательности никто не добавлял или еще не прошли модерацию. Попробуйте получить их позже.
                </template>
            </MapItemsListDialog>
        </ion-modal>
        
        <ion-modal
            :is-open="isOpenModalPointInfo"
            css-class="my-custom-class"
            @didDismiss="isOpenModalPointInfo = false"
        >
            <ModalPointInfo
                :coords="pointInfoObj.coords"
                :desc="pointInfoObj.desc"
                :images="pointInfoObj.image"
                :name="pointInfoObj.name"
                :place="pointInfoObj.place"
                :company="pointInfoObj.company"
                title="Информация о достопримечательности"
                @close="isOpenModalPointInfo = false"
            ></ModalPointInfo>
        </ion-modal>
        
        <ion-modal
            :is-open="isOpenModalEventInfo"
            css-class="my-custom-class"
            @didDismiss="isOpenModalEventInfo = false"
        >
            <ModalPointInfo
                :coords="eventInfoObj.coords"
                :desc="eventInfoObj.desc"
                :images="eventInfoObj.image"
                :name="eventInfoObj.name"
                :place="eventInfoObj.place"
                title="Информация о событии"
                @close="isOpenModalEventInfo = false"
            ></ModalPointInfo>
        </ion-modal>
        
        <!--<div-->
        <!--    v-if="preloadAction"-->
        <!--    style="position: absolute; top: 0; left: 0; right: 0; bottom: 0;"-->
        <!--&gt;-->
        <!--    <div style="width: 100%; height: 100%; display:flex; justify-content: center; align-items: center; background-color: rgba(0, 0, 0, 0.4);">-->
        <!--        <ion-spinner-->
        <!--            slot="start"-->
        <!--            name="crescent"-->
        <!--            style="width: 50px; height: 50px;"-->
        <!--        ></ion-spinner>-->
        <!--    </div>-->
        <!--</div>-->
    </div>
</template>

<script>
import axios from 'axios';
import AddPoint from '@/pages/Tabs/Places/AddPlace.vue';
import ModalPointInfo from '@/components/ModalPointInfo.vue';
import MapItemsList from '@/components/MapItemsList.vue';
import MapItemsListDialog from '@/components/MapItemsListDialog.vue';
import AddEvent from './AddEvent';

export default {
    name: 'YandexMapContainer',
    
    components: {
        AddEvent,
        MapItemsListDialog,
        MapItemsList,
        AddPoint,
        ModalPointInfo,
    },
    
    data: () => ({
        lastActivePlacemark:   null,
        placemarks:            [],
        erros:                 [],
        loadMap:               false,
        map:                   null,
        test1:                 null,
        msg:                   'This is a button.',
        isOpenModalAddPoint:   false,
        isOpenModalEventPoint: false,
        
        isOpenModalPointsList: false,
        
        preloadAction: false,
        
        isOpenModalPointInfo: false,
        pointInfoObj:         {
            name:   null,
            desc:   null,
            coords: null,
            place:  null,
            image:  null,
        },
        
        isOpenModalEventInfo: false,
        eventInfoObj:         {
            name:   null,
            desc:   null,
            coords: null,
            place:  null,
            image:  null,
        },
    }),
    
    mounted() {
        const url = 'https://api-maps.yandex.ru/2.1/?apikey=9cf9adcd-0f8e-4b7d-8b15-e0c5dd74b81a&lang=ru_RU';
        let scriptYandexMap = document.querySelector(`script[src="${ url }"]`);
        console.log('find script ymaps on YandexMapContainer');
        
        if (!scriptYandexMap) {
            console.log('script ymaps on YandexMapContainer does not found, create');
            // Установить скрипты для использования яндекс карты
            scriptYandexMap = document.createElement('script');
            scriptYandexMap.setAttribute('src', url);
            document.head.appendChild(scriptYandexMap);
            // Инициализировать яндекс карту
            scriptYandexMap.addEventListener('load', this.initializeYandexMap);
        }
        else {
            if (ymaps) {
                console.log('script ymaps on YandexMapContainer found success and ymaps found, initialize');
                this.initializeYandexMap();
            }
            else {
                console.log('script ymaps on YandexMapContainer found success, but not loaded. Wait for it and then initialize');
                scriptYandexMap.addEventListener('load', this.initializeYandexMap);
            }
        }
    },
    
    methods: {
        getPlacemark(number) {
            this.isOpenModalPointsList = false;
            this.test1(number);
            
            if (this.lastActivePlacemark != null) {
                this.placemarks[this.lastActivePlacemark].isActive = false;
            }
            
            this.placemarks[number].isActive = true;
            this.lastActivePlacemark = number;
            //let map = this.map
            /*map.events.fire('dblclick', {
              position : [57.9315097360319,59.98323203173826]
            });*/
            //console.log(placemark.options)
            //this.map.geoObjects.get(number).options.set('iconColor', 'green')
            //this.map.geoObjects.add(placemark)
        },
        async initializeYandexMap() {
            console.log('start initializeYandexMap on YandexMapContainer');
            let response = null;
            
            try {
                response = await axios.post('/api/geoobject/show');
                this.placemarks = response.data.data.map(v => ({ ...v, isActive: false }));
            }
            catch (err) {
                console.error('ERROR initializeYandexMap');
                console.error(err);
                this.erros.push(err);
                this.$error('Произошла ошибка при получении списка достопримечательностей. Попробуйте позже или обратитесь в техподдержку.');
            }
            
            this.loadMap = true;
            let maps = ymaps;
            
            maps.ready({ successCallback: init, context: this });
            
            function init() {
                console.log('start init() on YandexMapContainer');
                
                // Создание карты.
                let map = new maps.Map('map', {
                    // Координаты центра карты.
                    // Порядок по умолчанию: «широта, долгота».
                    // Чтобы не определять координаты центра карты вручную,
                    // воспользуйтесь инструментом Определение координат.
                    center: [ 57.9615097360319, 59.95323203173826 ],
                    // Уровень масштабирования. Допустимые значения:
                    // от 0 (весь мир) до 19.
                    zoom: 11,
                });
                this.map = map;
                
                if (this.$isMobile) {
                    const firstButton = new ymaps.control.Button({
                        data:    {
                            content: 'Открыть список достопримечательностей',
                        },
                        options: {
                            maxWidth: 150,
                            size:     'large',
                            layout:   ymaps.templateLayoutFactory.createClass(
                                // Если кнопка не нажата, применяется CSS стиль 'myButton'.
                                // Если кнопка нажата, к ней применятся CSS-стили 'myButton' и 'myButtonSelected'.
                                
                                '<div class=\'myButton {% if state.selected %}myButtonSelected{% endif %}\' ' +
                                '    title=\'{{ data.title }}\' ' +
                                '    style=\'background-color: #e2f5ff; ' +
                                '       border-radius: 3px; ' +
                                '       display: flex;' +
                                '       cursor: pointer;' +
                                '       overflow: hidden;' +
                                '       padding: 8px 5px;' +
                                '       max-width: 200px;' +
                                '       align-items: center;' +
                                '       box-shadow: 0 1px 2px 1px rgb(0 0 0 / 15%), 0 2px 5px -3px rgb(0 0 0 / 15%);' +
                                '       font-family: Arial; ' +
                                '       font-size: 14px; ' +
                                '       font-weight: 400; ' +
                                '       font-style: normal; ' +
                                '       font-stretch: normal;\'' +
                                '>' +
                                '   <ion-icon name="prism" style=\'font-size: 25px; margin-right: 5px;\'></ion-icon>' +
                                '   {{ data.content }}' +
                                '</div>',
                            ),
                        },
                    });
                    
                    map.controls.add(firstButton, { float: 'none', position: { bottom: '40px', left: '8px' } });
                    
                    firstButton.events.add([ 'click' ], async () => {
                        await this.openModalPointsList();
                    });
                }
                
                let BalloonContentLayout = maps.templateLayoutFactory.createClass(
                    `<div
                        id="balloon-header"
                        style="--background: var(--ion-background-color, #fff);
                               --color: var(--ion-text-color, #000);
                               --padding-top: 0px;
                               --padding-bottom: 0px;
                               --padding-start: 0px;
                               --padding-end: 0px;
                               --keyboard-offset: 0px;
                               --overflow: auto;
                               visibility: inherit;
                               --offset-top: 56px;
                               --offset-bottom: 57px;
                               text-align: left!important;
                               color: #000;
                               -webkit-print-color-adjust: exact;
                               cursor: inherit;
                               font: 13px/20px Arial,sans-serif;
                               -webkit-tap-highlight-color: transparent;
                               max-width: none;
                               max-height: none;
                               display: block;
                               margin: 0 0 5px;
                               font-size: 120%;
                               font-weight: 700;
                               box-sizing: content-box;
                               cursor:pointer;
                        "
                    >
                        <a>
                            {{ properties.name }}
                            {% if properties.company.name %}
                            /
                            {{ properties.company.name }}
                            {% endif %}
                        </a>
                    </div>` +
                    '<p>{{ properties.desc }}</p>' +
                    `<div style="width: 100%; display:flex; justify-content: flex-end">
                        <div id="balloon-button" style="padding: 5px 10px; background-color: #000; color: #fff;border-radius: 5px; width: fit-content; cursor:pointer;">Подробнее</div>
                    </div>`,
                    {
                        balloonHeader: null,
                        balloonButton: null,
                        
                        // Переопределяем функцию build, чтобы при создании макета начинать
                        // слушать событие click на кнопке-счетчике.
                        build: function () {
                            // Сначала вызываем метод build родительского класса.
                            BalloonContentLayout.superclass.build.call(this);
                            this.balloonButton = document.getElementById('balloon-button');
                            this.balloonHeader = document.getElementById('balloon-header');
                            
                            this.balloonButton.addEventListener('click', () => this.openInfo.call(this), true);
                            this.balloonHeader.addEventListener('click', () => this.openInfo.call(this), true);
                        },
                        
                        // Аналогично переопределяем функцию clear, чтобы снять
                        // прослушивание клика при удалении макета с карты.
                        clear: function () {
                            // Выполняем действия в обратном порядке - сначала снимаем слушателя,
                            // а потом вызываем метод clear родительского класса.
                            this.balloonButton.removeEventListener('click', this.openInfo, true);
                            this.balloonHeader.removeEventListener('click', this.openInfo, true);
                            BalloonContentLayout.superclass.clear.call(this);
                        },
                        
                        openInfo: function () {
                            const data = this._data.properties._data;
                            
                            if (data.isEvent) {
                                data.showEventInfo(data);
                            }
                            else {
                                data.showPointInfo(data);
                            }
                        },
                    },
                );
                
                for (let item of this.placemarks) {
                    let legal = item.company || {};
                    let pointColor = 'black';
                    
                    if (item.isEvent) pointColor = 'orange';
                    else if (legal.name) pointColor = 'blue';
                    
                    let placemark = new maps.Placemark(item.coords, {
                        // ...item - вытаскиваю все поля точки и передаю их сюда,
                        //           ими можно будет потом пользоваться при построении интерфейса
                        // showPointInfo:this.openModalPointInfo - также передаю функцию, которая используется
                        //                                         для открытия модального окна с информацией
                        // showPointInfo:this.openModalPointInfo - тоже самое, но для событий
                        ...item,
                        showPointInfo: this.openModalPointInfo,
                        showEventInfo: this.openModalEventInfo,
                        iconCaption:   item.name,
                    }, {
                        balloonCloseButton:     true, // Отключаем кнопку закрытия балуна.
                        balloonContentLayout:   BalloonContentLayout, // задаем собственный макет
                        hideIconOnBalloonOpen:  false, // Балун будем открывать и закрывать кликом по иконке метки.
                        balloonPanelMaxMapArea: (this.$isMobile) ? Infinity : 0,
                        iconColor:              pointColor,
                    });
                    
                    map.geoObjects.add(placemark);
                }
                
                function test1(number) {
                    console.log(number);
                    const geoObject = map.geoObjects.get(number);
                    geoObject.options.set('iconColor', 'green');
                    geoObject.events.fire('click', {});
                }
                
                this.test1 = test1;
            }
        },
        
        async openModalPointsList() {
            this.isOpenModalPointsList = true;
        },
        
        async openModalAddPoint() {
            this.isOpenModalAddPoint = true;
            // const modal = await modalController
            //     .create({
            //         component:      Modal,
            //         cssClass:       'addPoint',
            //         componentProps: {
            //             title: 'Добавить достопримечательность',
            //         },
            //     });
            // return modal.present();
        },
        
        async openModalEventPoint() {
            this.isOpenModalEventPoint = true;
            // const modal = await modalController
            //     .create({
            //         component:      Modal,
            //         cssClass:       'addPoint',
            //         componentProps: {
            //             title: 'Добавить достопримечательность',
            //         },
            //     });
            // return modal.present();
        },
        
        async openModalPointInfo(placemark) {
            event.preventDefault();
            event.stopPropagation();
            
            this.pointInfoObj.desc = placemark.desc;
            this.pointInfoObj.coords = placemark.coords;
            this.pointInfoObj.name = placemark.name;
            this.pointInfoObj.image = placemark.img;
            this.pointInfoObj.place = placemark.place;
            this.pointInfoObj.company = placemark.company || {};
            this.isOpenModalPointInfo = true;
        },
        
        async openModalEventInfo(placemark) {
            event.preventDefault();
            event.stopPropagation();
            
            this.eventInfoObj.desc = placemark.desc;
            this.eventInfoObj.coords = placemark.coords;
            this.eventInfoObj.name = placemark.name;
            this.eventInfoObj.image = placemark.img;
            this.eventInfoObj.place = placemark.place;
            this.isOpenModalEventInfo = true;
        },
    },
};
</script>

<style scoped>
#map {
    width: 100%;
    height: 100%;
}
</style>
