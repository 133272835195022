<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <YandexMapContainer></YandexMapContainer>
        </ion-content>
    </ion-page>
</template>

<script>
import YandexMapContainer from '@/pages/Tabs/Places/YandexPlaceContainer.vue';

export default {
    name:       'PlacesIndex',
    components: {
        YandexMapContainer,
    },
};
</script>

<style scoped>

</style>
